<template>

  <div cds-layout="vertical align:stretch">
    <div cds-layout="vertical align:shrink" class="login-container">
      <div class="cross horizontal">
        <span class="title">
          <template v-if="this.currentComponent != 'CurrentlyLoading'">
            {{ config.software_name }}
          </template>
          <template v-else>
            &#8205;
          </template>
        </span>
        <div class="cross vertical" />
        <div class="loading-bar" />
      </div>

      <div cds-layout="vertical gap:xxl" class="cross body">
        <component :is="this.currentComponent" @on-login="onLogin"
          @change-component="(component) => this.currentComponent = component" />
      </div>
    </div>

  </div>

  <div class="alert">
    <div cds-layout="vertical gap:md">
      <AlertComponent v-for="alert in $store.getters['util/getAlerts']" :key="alert" :status="alert.status"
        :message="alert.message" />
    </div>
  </div>

</template>

<script>
import store from './store';
import UsernamePasswordLogin from './components/login/UsernamePasswordLogin.vue';
import CurrentlyLoading from './components/login/CurrentlyLoading.vue';
import ResetPassword from './components/login/ResetPassword.vue';
import HelpComponent from './components/settings/HelpComponent.vue';
import AlertComponent from "@/components/alert/AlertComponent.vue";
import config from "@/config/Config";
import { settingsMixin } from './components/settings/SettingsMixin';

export default {
  emits: ["onInitializationDone", "onLogin", "onLoadingDone", "onSelected"],
  mixins: [settingsMixin],
  components: {
    UsernamePasswordLogin,
    CurrentlyLoading,
    ResetPassword,
    HelpComponent,
    AlertComponent,
  },
  data() {
    return {
      config,
      currentComponent: "UsernamePasswordLogin",
    }
  },
  mounted: function () {
    // Set default accent color
    this.setAccentColor(config.default_color);
  },
  methods: {
    onLogin: async function (user) {
      this.$emit("onLogin", user);
      this.currentComponent = "CurrentlyLoading";
      await this.loadGravemanagement();
      this.$emit("onInitializationDone");
    },
    loadGravemanagement: async function () {
      const cross_horizontal = document.querySelector(".cross.horizontal");
      cross_horizontal.style.borderBottom = "0.5px solid lightgray";
      cross_horizontal.style.boxShadow = "none";
      const loadingBar = document.querySelector(".loading-bar");
      let totalSteps = 200;
      let currentStep = 0;

      return new Promise((resolve) => {
        const interval = setInterval(() => {

          // If more than one graveyard config, shorten loading time
          const configurations = store.getters["user/getAvailableConfigurations"];
          if (configurations) {
            if (configurations.length > 1) {
              const percentage = currentStep / totalSteps;
              totalSteps = 25;
              currentStep = totalSteps * percentage;
            }
          }

          if (currentStep < totalSteps) currentStep++;

          const progressPercentage = (currentStep / totalSteps) * 90;
          loadingBar.style.width = progressPercentage + "%";


          if (currentStep >= totalSteps && store.getters["management/isConfigurationLoading"] == false) {
            loadingBar.style.width = "100%";
            clearInterval(interval);
            resolve();
          }
        }, 20);
      });
    },
  },
}
</script>

<style scoped>
.login-container {
  align-items: center;
  margin-top: 150px;
}

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 48.41px;
}

.cross.horizontal {
  position: relative;
  border-bottom: 1.5px solid var(--cds-alias-status-info);
  box-shadow: 0px 4px 2px -2px #00000040;
  min-width: 500px;
  width: 60%;
  max-width: 800px;
  padding-left: 150px;
  padding-bottom: 5px;
}

.cross.vertical {
  position: absolute;
  border-right: 1.5px solid var(--cds-alias-status-info);
  height: 500px;
  left: 120px;
  top: -40px;
}

.cross.body {
  min-width: 500px;
  width: 60%;
  padding-left: 150px;
  max-width: 700px;
  margin-top: 100px;
}

.loading-bar {
  position: absolute;
  left: 0px;
  bottom: -1px;
  border-bottom: 1.5px solid var(--cds-alias-status-info);
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 0%;

  transition: width 20ms linear;
}

.alert {
  position: absolute;
  z-index: 999;
  top: 70px;
  right: 20px;
}
</style>