<template>
    <div :cds-layout="`vertical align:center gap:md ${this.topMargin ? 'm-t:xxxl' : ''}`">
        <div cds-layout="horizontal align:stretch p-l:xxxl p-r:xxxl">
            <LoadingButtonComponent class="login-button" :loading="this.loading" :error="this.error"
                @on-click="this.$emit('onClick')">
                <slot />
            </LoadingButtonComponent>
        </div>

        <div cds-layout="vertical align:center gap:xxs">
            <slot name="under" />
        </div>
    </div>
</template>

<script>
import '@cds/core/button/register.js';
import LoadingButtonComponent from '../input/LoadingButtonComponent.vue';

export default {
    emits: ["onClick"],
    props: {
        loading: Boolean,
        error: Boolean,
        topMargin: Boolean,
    },
    components: {
        LoadingButtonComponent,
    },
}
</script>

<style scoped></style>