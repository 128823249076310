<template>
    <cds-select :class="{ 'no-pointer-events': this.readonly }">
        <label></label>
        <select v-model="value">
            <option v-for="option in this.data" :key="option" :value="option.id">
                {{ option.label }}
            </option>
        </select>
        <cds-control-message v-if="this.optionsLoading">{{ this.label }}: Optionen werden
            geladen...</cds-control-message>

        <cds-control-message v-else-if="this.valid">
            {{ this.label }}
            <span v-if="this.required" class="required">*</span>
        </cds-control-message>
        <cds-control-message v-else status="error">{{ this.error_message }}</cds-control-message>
    </cds-select>
</template>

<script>
import '@cds/core/select/register.js';

export default {
    emits: ["update:modelValue", "onValidation"],
    props: {
        modelValue: null,
        label: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            valid: true,
            error_message: null
        }
    },
    computed: {
        optionsLoading: function () {
            return this.data == null;
        },
        value: {
            get() {
                if (this.data == null) return null;

                const isID = this.data.some(option => option.id === this.modelValue);

                if (isID)
                    return this.modelValue;

                const option = this.data.find(option => option.label === this.modelValue);
                return option ? this.$emit('update:modelValue', option.id) : undefined;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        validate: function () {
            this.resetValidation();

            if (this.required && this.value === undefined) {
                this.valid = false;
                this.error_message = this.label + ": Eingabe fehlt!";
                return this.$emit("onValidation", false);
            }

            return this.$emit("onValidation", true);
        },
        resetValidation: function () {
            this.valid = true;
            this.error_message = "";
        }
    }
}
</script>

<style scoped>
.required {
    color: red;
}

.no-pointer-events {
    opacity: 75%
}
</style>