<template>
    <cds-input :class="{ 'no-pointer-events': this.readonly }">
        <label></label>
        <input :type="this.type" v-model="value" />
        <cds-control-message v-if="this.valid">
            {{ this.label }}
            <span v-if="this.required" class="required">*</span>
        </cds-control-message>
        <cds-control-message v-else status="error">{{ this.error_message }}</cds-control-message>
    </cds-input>
</template>

<script>
import '@cds/core/input/register.js';

export default {
    emits: ["update:modelValue", "onValidation"],
    props: {
        modelValue: {
            type: [String, Number],
            default: null
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: [Number, String, Object],
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            valid: true,
            error_message: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        validate: function () {
            this.resetValidation();

            if (this.required && !this.value) {
                this.valid = false;
                this.error_message = this.label + ": Eingabe fehlt!";
                return this.$emit("onValidation", false);
            } else if (this.value && this.type === "email" && !this.isValidEmail(this.value)) {
                this.valid = false;
                this.error_message = this.label + ": Keine richtige E-Mail-Adresse!"
                return this.$emit("onValidation", false);
            }

            return this.$emit("onValidation", true);
        },
        resetValidation: function () {
            this.valid = true;
            this.error_message = "";
        },
        isValidEmail: function (email) {
            // Define the regex for a valid email address
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Test the email against the regex
            return emailRegex.test(email);
        },
    }
}
</script>

<style scoped>
.required {
    color: red;
}

.no-pointer-events {
    opacity: 75%
}
</style>