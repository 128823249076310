<template>
  <OverlayTemplate :show="this.show" :closeIcon="true" @onClose="this.$emit('onClose')">
    <template v-slot:content>
      <div cds-layout="vertical gap:xl p:md">
        <!-- Content -->
        <slot name="content" />

        <!-- Buttons -->
        <div cds-layout="horizontal gap:md align:right">
          <LoadingButtonComponent action="outline" :loading="secondaryButtonLoading" :error="secondaryButtonError"
            @click="this.$emit('onSecondaryClick')" @on-loading-done="this.$emit('onSecondaryLoadingDone')">
            {{ this.secondaryButton }}
          </LoadingButtonComponent>
          <LoadingButtonComponent :loading="primaryButtonLoading" :error="primaryButtonError"
            @click="this.$emit('onPrimaryClick')" @on-loading-done="this.$emit('onPrimaryLoadingDone')">
            {{ this.primaryButton }}
          </LoadingButtonComponent>
        </div>
      </div>
    </template>
  </OverlayTemplate>
</template>

<script>
import OverlayTemplate from "@/components/overlay/OverlayTemplate.vue";
import LoadingButtonComponent from "../input/LoadingButtonComponent.vue";

export default {
  components: {
    OverlayTemplate,
    LoadingButtonComponent
  },
  props: {
    show: Boolean,
    primaryButton: {
      type: String,
      default: "Ja",
    },
    secondaryButton: {
      type: String,
      default: "Nein",
    },
    primaryButtonLoading: Boolean,
    secondaryButtonLoading: Boolean,
    primaryButtonError: Boolean,
    secondaryButtonError: Boolean
  },
  emits: ["onClose", "onPrimaryClick", "onSecondaryClick", "onPrimaryLoadingDone", "onSecondaryLoadingDone"],
};
</script>

<style></style>
