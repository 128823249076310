<template>
    <div>
        <div cds-layout="vertical gap:xxl p:xxl align:center" style="min-height: 100vh">

            <!-- Heading -->
            <div cds-layout="vertical gap:md align:horizontal-center">
                <span cds-text="h1 medium center">Auswahl</span>
                <hr>
            </div>

            <!-- Selection options -->
            <div cds-layout="horizontal gap:xl align:horizontal-center">
                <selectionCardComponent v-for="option in options" :key="option.graveyard_id"
                    :name="option.graveyard_name" @on-click="selectionClicked(option)" />
            </div>

        </div>
    </div>
</template>

<script>
import selectionCardComponent from './components/selection/selectionCardComponent.vue';
import store from './store';

export default {
    components: {
        selectionCardComponent
    },
    emits: ["onSelected"],
    data() {
        return {
            options: Array
        }
    },
    beforeMount: async function () {
        this.options = store.getters["user/getAvailableConfigurations"];
    },
    methods: {
        selectionClicked(selected) {
            store.dispatch("user/setCurrentConfiguration", selected);

            // Clear caches
            store.dispatch("util/clearTableCache");
            store.dispatch("util/clearDocumentsCache");

            this.$emit("onSelected");
        }
    }
}
</script>

<style scoped>
hr {
    min-width: 500px;
    width: 60%;
    max-width: 800px;
    border: 1.5px solid var(--cds-alias-status-info);
    box-shadow: 0px 4px 4px 0px #00000040;
}
</style>