<template>
    <div cds-layout="vertical align:stretch">
        <div cds-layout="grid align@sm:vertical-stretch align:vertical-shrink" class="grid">
            <div cds-layout="col@sm:1 col:12" class="drawer">
                <DrawerComponent />
            </div>

            <div cds-layout="vertical col@sm:11 col:12" class="view">
                <HeaderComponent @on-change-configuration="this.$emit('onInitializationDone')" />
                <div cds-layout="grid align:stretch" class="content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>


    <div class="alert">
        <div cds-layout="vertical gap:md">
            <AlertComponent v-for="alert in $store.getters['util/getAlerts']" :key="alert" :status="alert.status"
                :message="alert.message" />
        </div>
    </div>

    <!-- Confirmation overlay for leaving with unsaved changes -->
    <ButtonOverlay :show="this.showConfirmLeave" primaryButton="Fortfahren" secondaryButton="Bleiben"
        @on-primary-click="this.continueNextRoute()" @on-secondary-click="this.cancelNextRoute()"
        @on-close="this.cancelNextRoute()">
        <template v-slot:content>
            <p cds-text="section">Möchten Sie wirklich fortfahren?</p>
            <p cds-text="body">Wenn Sie fortfahren, gehen ungespeicherte Daten verloren.</p>
        </template>
    </ButtonOverlay>
</template>

<script>
import HeaderComponent from './components/header/HeaderComponent.vue';
import DrawerComponent from './components/drawer/DrawerComponent.vue';
import AlertComponent from "@/components/alert/AlertComponent.vue";
import ButtonOverlay from './components/overlay/ButtonOverlay.vue';
import store from './store';
import config from "@/config/Config";
import { settingsMixin } from './components/settings/SettingsMixin';
import router from './router';

export default {
    emits: ["onInitializationDone", "onLogin", "onSelected", "onLoadingDone"],
    mixins: [settingsMixin],
    components: {
        HeaderComponent,
        DrawerComponent,
        AlertComponent,
        ButtonOverlay,
    },
    data() {
        return {
            showConfirmLeave: false,
            routerNext: null,
        }
    },
    beforeMount() {
        // Set title for browser tab
        const configuration = store.getters["user/getCurrentConfiguration"];
        document.title = `${config.software_name} - ${configuration.graveyard_name}`;

        // Apply settings for this configuration that have been stored in the session storage
        const configSettings = JSON.parse(sessionStorage.getItem("configSettings"));
        this.setAccentColor(configSettings.accentColor);
        this.setFontScale(configSettings.fontScale);

        // Show confirmation dialog on route change if there's unsaved data
        router.beforeEach((to, from, next) => {
            if (store.getters["management/isEditing"]) {
                this.routerNext = next;
                this.showConfirmLeave = true;
            } else {
                next();
            }
        });
    },
    beforeUnmount() {
        // Set default settings
        this.setAccentColor(config.default_color);
        this.setFontScale(1);
    },
    methods: {
        continueNextRoute() {
            this.showConfirmLeave = false;
            store.dispatch("management/setEditing", false);
            this.routerNext();
        },
        cancelNextRoute() {
            this.showConfirmLeave = false;
            this.routerNext(false);
        },
    },
}
</script>

<style scoped>
.loading-screen {
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid {
    flex: 1;
    max-height: 100vh;
}

@media only screen and (max-width: 767px) {

    /* For mobile phones: */
    .drawer {
        position: absolute;
    }
}

.drawer {
    height: 100vh;
}

.view {
    max-height: 100vh;
}

.content {
    overflow: auto;
}

.alert {
    position: absolute;
    z-index: 999;
    top: 70px;
    right: 20px;
}
</style>