<template>
    <!-- <cds-textarea :class="{ 'no-pointer-events': this.readonly }">
        <label></label>
        <textarea v-model="value" />
        <cds-control-message v-if="this.valid">
            {{ this.label }}
            <span v-if="this.required" class="required">*</span>
        </cds-control-message>
        <cds-control-message v-else status="error">{{ this.error_message }}</cds-control-message>
    </cds-textarea> -->

    <!-- Quasar textarea as a workaround to replace cds-textarea -->
    <div :class="{ 'no-pointer-events': this.readonly }">
        <div cds-layout="vertical gap:xs p-t:xxs">
            <q-input v-model="value" borderless type="textarea" class="full-width" />
            <cds-control-message v-if="this.valid">
                {{ this.label }}
                <span v-if="this.required" class="required">*</span>
            </cds-control-message>
            <cds-control-message v-else status="error">{{ this.error_message }}</cds-control-message>
        </div>
    </div>
</template>

<script>
import '@cds/core/textarea/register.js';

export default {
    emits: ["update:modelValue", "onValidation"],
    props: {
        modelValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            valid: true,
            error_message: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        validate: function () {
            this.resetValidation();

            if (this.required && !this.value) {
                this.valid = false;
                this.error_message = this.label + ": Eingabe fehlt!";
                return this.$emit("onValidation", false);
            }

            return this.$emit("onValidation", true);
        },
        resetValidation: function () {
            this.valid = true;
            this.error_message = "";
        }
    }
}
</script>

<style scoped>
.required {
    color: red;
}

.no-pointer-events {
    opacity: 75%
}

/* Below is for the Quasar textarea, remove once the bug with Clarity's textarea is fixed */
:deep(.q-field__native) {
    border: var(--cds-alias-object-border-width-100, calc(1 * 1rem / var(--cds-global-base, 20))) solid var(--cds-alias-object-interaction-border-color, var(--cds-global-color-construction-500, #6a7a81));
    border-radius: var(--cds-alias-object-border-radius-100, calc(4 * 1rem / var(--cds-global-base, 20)));

    padding: var(--cds-global-space-5, calc(8 * 1rem / var(--cds-global-base, 20))) var(--cds-global-space-6, calc(12 * 1rem / var(--cds-global-base, 20)));

    font-size: var(--cds-global-typography-font-size-3, calc(13 * 1rem / var(--cds-global-base, 20)));
    color: var(--cds-global-typography-color-400, var(--cds-global-color-construction-900, #21333b));
    line-height: 1.15;

    min-height: var(--cds-global-space-15, calc(96 * 1rem / var(--cds-global-base, 20)));
    height: var(--cds-global-space-15, calc(96 * 1rem / var(--cds-global-base, 20)));
}

:deep(.q-field__native:focus) {
    border-color: var(--cds-global-color-construction-1000);
    border-width: var(--cds-alias-object-border-width-200);

    padding: calc(var(--cds-global-space-5, calc(8 * 1rem / var(--cds-global-base, 20))) - (var(--cds-alias-object-border-width-200) / 2)) calc(var(--cds-global-space-6, calc(12 * 1rem / var(--cds-global-base, 20))) - (var(--cds-alias-object-border-width-200) / 2));
}
</style>