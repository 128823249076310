<template>
    <div cds-layout="grid gap:xxl">
        <div :cds-layout="`col:${loggedIn ? '8' : '12'} vertical gap:xxl container:xs`">
            <div cds-layout="grid cols@lg:6 cols:12 vertical gap:md">
                <p cds-layout="col:12" cds-text="section">Persönliche Daten</p>
                <InputComponent label="Vorname" v-model="this.firstName" required
                    :readonly="this.loggedIn && this.firstName ? true : false" @on-validation="onValidation"
                    ref="firstName" />
                <InputComponent label="Nachname" v-model="this.lastName" required
                    :readonly="this.loggedIn && this.lastName ? true : false" @on-validation="onValidation"
                    ref="lastName" />
                <InputComponent cds-layout="col:12" label="E-Mail" type="email" v-model="this.email" required
                    :readonly="this.loggedIn && this.email ? true : false" @on-validation="onValidation" ref="email" />
            </div>

            <div cds-layout="vertical gap:md">
                <p cds-text="section">Hier können Sie ihr Problem beschreiben:</p>

                <SelectComponent label="Grund" :data="reasonOptions" v-model="this.reason" required
                    @on-validation="onValidation" ref="reason" />

                <div cds-layout="p-l:lg" class="full-width">
                    <TextareaComponent label="Problembeschreibung" v-model="this.description" required
                        @on-validation="onValidation" ref="description" />
                </div>

                <!-- TODO: Replace with custom file component once it exists -->
                <cds-file>
                    <label />
                    <input type="file" multiple accept="image/*" @input="filesSelected" />
                    <cds-control-message v-if="!this.fileError">Anhänge</cds-control-message>
                    <cds-control-message v-else status="error">Anhänge: {{ this.fileError }}</cds-control-message>
                </cds-file>
            </div>

            <component :is="this.loggedIn ? 'LoadingButtonComponent' : 'LoginButton'"
                v-bind="loggedIn ? { 'cds-layout': 'align:right' } : { 'class': 'full-width' }"
                :loading="this.buttonLoading" :error="this.buttonError" @on-click="send()">
                <template v-slot:default>Anfrage senden</template>

                <template v-slot:under v-if="!loggedIn">
                    <cds-button-inline @click="this.$emit('changeComponent', 'UsernamePasswordLogin')">Zurück zum
                        Login</cds-button-inline>
                </template>
            </component>
        </div>

        <div v-if="loggedIn" cds-layout="col:4 horizontal gap:xxl align:right wrap:none" class="full-height">
            <cds-divider orientation="vertical" />

            <div cds-layout="vertical gap:md m-t:xxxl">
                <p cds-text="section">Weitere Kontaktdaten</p>
                <p cds-text="body">E-Mail: <a href="mailto:help@tibke-it.de" cds-text="link">help@tibke-it.de</a></p>
                <p cds-text="body">Telefon: <a href="tel:+4915170106037" cds-text="link">+49 151 70106037</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import '@cds/core/divider/register.js';
import '@cds/core/file/register.js';
import '@cds/core/button/register.js';
import '@cds/core/button-inline/register.js';
import InputComponent from '../input/InputComponent.vue';
import SelectComponent from '../input/SelectComponent.vue';
import TextareaComponent from '../input/TextareaComponent.vue';
import LoadingButtonComponent from '../input/LoadingButtonComponent.vue';
import LoginButton from '../login/LoginButton.vue';
import store from '@/store';
import { nextTick } from 'vue';
import RestClient from '@/js/rest/RestClient';
import publishAlert from '@/js/util/Alert';

export default {
    emits: ["changeComponent"],
    components: {
        InputComponent,
        SelectComponent,
        TextareaComponent,
        LoadingButtonComponent,
        LoginButton,
    },
    data() {
        return {
            reasonOptions: [
                {
                    label: "Login",
                    id: "Login",
                },
                {
                    label: "Daten anlegen",
                    id: "Daten anlegen",
                },
                {
                    label: "Einstellungen",
                    id: "Einstellungen",
                },
                {
                    label: "Sonstiges",
                    id: "Sonstiges",
                },
            ],
            loggedIn: false,
            firstName: null,
            lastName: null,
            email: null,
            reason: null,
            description: null,
            files: null,
            buttonLoading: false,
            buttonError: false,
            allValid: true,
            fileError: null, // TODO: Remove once custom file component exists
        }
    },
    beforeMount: function () {
        this.getUser();
    },
    methods: {
        getUser: function () {
            // Get user from database and get user email
            const dbUser = store.getters["user/getDatabaseUser"];
            const emailUser = store.getters["user/getUser"];

            if (dbUser && emailUser) {
                this.firstName = dbUser.firstname;
                this.lastName = dbUser.lastname;
                this.email = emailUser.email;

                this.loggedIn = true;
            }
        },
        // TODO: Remove once a custom file component exists with v-model
        filesSelected: function (event) {
            this.files = event.target.files;
        },
        send: async function () {
            this.buttonLoading = true;

            if (this.validate()) {
                const requestBody = new FormData();
                requestBody.append("first_name", this.firstName);
                requestBody.append("last_name", this.lastName);
                requestBody.append("email", this.email);
                requestBody.append("reason", this.reason);
                requestBody.append("description", this.description);

                if (this.files) {
                    for (let i = 0; i < this.files.length; i++) {
                        requestBody.append("files", this.files[i]);
                    }
                }

                const restClient = new RestClient();
                const response = await restClient.postHelp(requestBody);

                if (response.ok) {
                    publishAlert("success", "Hilfeanfrage wurde gesendet");
                    this.buttonError = false;
                } else {
                    publishAlert("danger", "Hilfeanfrage konnte nicht gesendet werden");
                    this.buttonError = true;
                }

                await nextTick();
                this.buttonLoading = false;
            } else {
                await nextTick();
                this.buttonError = true;
                this.buttonLoading = false;
            }
        },
        validate: function () {
            const toValidate = [
                "firstName",
                "lastName",
                "email",
                "reason",
                "description",
            ];

            this.allValid = true;
            toValidate.forEach(ref => this.$refs[ref].validate());

            // TODO: Remove once a custom file component exists with a validate() method
            if (this.files) {
                let allFilesValid = true;
                for (let i = 0; i < this.files.length; i++) {
                    const file = this.files[i];
                    if (!file.type.startsWith("image/")) {
                        allFilesValid = false;
                        break;
                    }
                }

                if (allFilesValid) {
                    this.fileError = null;
                } else {
                    this.fileError = "Nur Bilder erlaubt!";
                    this.allValid = false;
                }
            }

            if (this.allValid) {
                return true;
            } else {
                return false;
            }
        },
        onValidation: function (valid) {
            if (!valid) {
                this.allValid = false;
            }
        },
    }
}
</script>

<style scoped></style>