import store from "@/store";
import RestClient from "./rest/RestClient";
import ConfigurationParser from "./ConfigurationParser";
import config from "@/config/Config";

export default class UserConfiguration {

    constructor() {
        this.configuration = store.getters["user/getUserConfiguration"];
        this.user = store.getters["user/getDatabaseUser"];

        if (!this.configuration) {
            this.configuration = {};
        }

        if (typeof this.configuration === "string") {
            this.configuration = JSON.parse(this.configuration);
        }

        if (!("settings" in this.configuration)) {
            this.configuration.settings = {};
        }

    }

    async getAccentColor(configurationID = null) {
        const currentConfigurationID = store.getters["user/getCurrentConfiguration"].graveyard_id;

        // Use passed down or current configuration ID
        configurationID = configurationID || currentConfigurationID;

        // Get from user configuration
        let accentColor = this.configuration.settings?.accentColor?.[configurationID];

        // Get from default configuration if not defined in user configuration 
        if (!accentColor) {
            let configurationParser;

            if (configurationID === currentConfigurationID) {
                configurationParser = new ConfigurationParser();
            } else {
                const restClient = new RestClient({graveyard_id: configurationID});
                const response = await restClient.getConfiguration();
                configurationParser = new ConfigurationParser(response.data);
            }

            const properties = configurationParser.getGraveManagementProperties();
            accentColor = properties.basecolor;
        }

        // Get application default if not defined in default configuration
        if (!accentColor) {
            accentColor = config.default_color;
        }

        return accentColor;
    }

    setAccentColor(accentColor, configurationID = null) {
        // Use passed down or current configuration ID
        configurationID = configurationID || store.getters["user/getCurrentConfiguration"].graveyard_id;

        if (!this.configuration.settings.accentColor) {
            this.configuration.settings.accentColor = {};
        }
        
        this.configuration.settings.accentColor[configurationID] = accentColor;
        this.updateConfiguration();
    }

    getFontScale() {
        // Get from user configuration
        let fontScale = this.configuration.settings?.fontScale;

        // Get default value if not defined in user configuration
        return fontScale ? fontScale : 1;
    }

    setFontScale(fontScale) {
        this.configuration.settings.fontScale = fontScale;
        this.updateConfiguration();
    }

    getDashboardTables() {
        const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
        return this.configuration.graveyards?.[graveyard_id]?.dashboard?.tables;
    }

    async postDashboardTable(table) {
        const no_id = table.id == null || table.id == undefined;
        table.id = no_id ? crypto.randomUUID() : table.id;

        const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
        if (this.configuration.graveyards?.[graveyard_id]?.dashboard?.tables)
            this.configuration.graveyards[graveyard_id].dashboard.tables.push(table);
        else if (this.configuration.graveyards?.[graveyard_id]?.dashboard)
            this.configuration.graveyards[graveyard_id].dashboard.tables = [table];
        else
            this.configuration.graveyards = {
                [graveyard_id]: {
                    "dashboard": {
                        "tables": [table]
                    }
                }
            }

        this.updateConfiguration();
    }

    async putDashboardTable(id, table) {
        try {
            const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
            const index = this.configuration.graveyards[graveyard_id].dashboard.tables.findIndex(custom_table => custom_table.id == id);
            this.configuration.graveyards[graveyard_id].dashboard.tables[index] = table;
        } catch (err) {
            console.error(err);
        }

        this.updateConfiguration();
    }

    async deleteDashboardTable(id) {
        try {
            const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
            this.configuration.graveyards[graveyard_id].dashboard.tables = this.configuration.graveyards[graveyard_id].dashboard.tables.filter(table => table.id != id);
        } catch (err) {
            console.error(err);
        }

        this.updateConfiguration();
    }

    async resetStandardTables() {
        try {
            const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
            this.configuration.graveyards[graveyard_id].dashboard.tables = this.configuration.graveyards[graveyard_id].dashboard.tables.filter(table => {
                const keys = Object.keys(table);
                const is_standard = keys.includes("removed") || keys.includes("standard");
                return is_standard == false;
            });
        } catch (err) {
            console.error(err);
        }

        this.updateConfiguration();
    }

    async updateConfiguration() {
        store.dispatch("user/setUserConfiguration", this.configuration);

        const restClient = new RestClient();
        restClient.patchCurrentUser({
            config: JSON.stringify(this.configuration)
        });
    }
}