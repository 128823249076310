<template>
    <div class="overlay" cds-layout="vertical align:center" v-if="this.show">
        <div class="content" cds-layout="p:lg">
            <span cds-text="caption" class="cancel" @click="this.emitClose()" v-if="this.closeIcon">
                schließen <cds-icon shape="window-close" size="20" />
            </span>
            <div cds-layout="horizontal aling:shrink align:center">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
import { ClarityIcons, windowCloseIcon } from '@cds/core/icon';

export default {
    emits: ["onClose"],
    props: {
        show: Boolean,
        closeIcon: Boolean
    },
    mounted() {
        ClarityIcons.addIcons(windowCloseIcon);
    },
    methods: {
        emitClose: function () {
            this.$emit("onClose");
        }
    }
}
</script>

<style scoped>
.overlay {
    position: absolute;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    background-color: rgba(233, 233, 233, 0.67);
    backdrop-filter: blur(2px);
}

.content {
    position: relative;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    max-width: 80%;
    max-height: 80%;
}

.cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
}

.cancel:hover {
    background-color: rgba(128, 128, 128, 0.1);
    cursor: pointer;
}
</style>