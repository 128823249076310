<template>
    <div class="drawerHeader" cds-layout="vertical p-t:md p-b:md">
        <div cds-layout="grid">
            <cds-icon shape="cross" size="60" cds-layout="col:1" />
            <div cds-layout="grid cols:12 gap:xs col:11">
                <span cds-text="message bold">{{ config.software_name }}</span>
                <span cds-text="secondary semibold">{{ this.graveManagementName }}</span>
                <span cds-text="caption" cds-layout="p-t:xs">{{ this.username }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import '@cds/core/icon/register.js';
import ConfigurationParser from '@/js/ConfigurationParser';
import store from '@/store';
import config from '@/config/Config';

export default {
    data() {
        const configurationParser = new ConfigurationParser();
        const graveManagementName = configurationParser.getGraveManagementName();

        return {
            config,
            graveManagementName,
        }
    },
    computed: {
        username() {
            const emailUser = store.getters["user/getUser"];
            const dbUser = store.getters["user/getDatabaseUser"];

            if (dbUser && dbUser.firstname && dbUser.lastname) {
                return `${dbUser.firstname} ${dbUser.lastname}`;
            } else {
                return emailUser.email;
            }
        }
    },
}
</script>

<style scoped>
.drawerHeader {
    border-bottom: 1px solid lightgray;
}
</style>