<template>
    <span cds-text="section medium" class="loading-text">
        {{ config.software_name }} wird vorbereitet ...
    </span>
</template>

<script>
import config from '@/config/Config';

export default {
    data() {
        return {
            config,
        }
    },
}
</script>

<style scoped>
.loading-text {
    color: var(--cds-global-color-gray-600);
}
</style>