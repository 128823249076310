<template>
  <component :is="this.currentView" @onLogin="user => initializeApplication(user)"
    @onInitializationDone="initializationDone()" @onSelected="loadConfiguration(true)"
    @onLoadingDone="updateView('AppView')" />
</template>

<script>
import AppView from './AppView.vue';
import LoginView from './LoginView.vue';
import SelectView from './SelectView.vue';
import LoadingCrossComponent from './components/loading/LoadingCrossComponent.vue';
import store from './store/index.js';
import RestClient from './js/rest/RestClient.js';
import UserConfiguration from './js/UserConfiguration';
import { ClarityIcons } from '@cds/core/icon';

export default {
  components: {
    AppView,
    LoginView,
    SelectView,
    LoadingCrossComponent
  },
  data() {
    return {
      currentView: "LoginView"
    }
  },
  methods: {
    initializeApplication: async function (user) {
      await this.saveUserToStore(user);
      store.dispatch("user/activateAutomaticTokenRefresh");

      const restClient = new RestClient();

      const dbUser = await restClient.getUser();
      store.dispatch("user/setDatabaseUser", dbUser.data);

      let retry = 0;
      let configsLoadingFailure = true;
      do {
        const graveyards = await restClient.getGraveyards();

        if (graveyards == null) {
          retry++;
        } else {
          store.dispatch("user/setAvailableConfigurations", graveyards.data);
          retry = 10;
          configsLoadingFailure = false;

          // If user has access to only one graveyard, it will be loaded directly
          if (graveyards.data.length === 1) {
            store.dispatch("user/setCurrentConfiguration", graveyards.data[0]);
            await this.loadConfiguration();
          }
        }

        await new Promise(r => setTimeout(r, 1000));
      } while (retry < 10)

      if (configsLoadingFailure) {
        return console.log("Configs couldn't load");
      }

      // Add custom icons
      this.addCustomIcons();

      // Loading finished
      store.dispatch("management/setConfigurationLoading", false);
    },
    initializationDone() {
      const configurations = store.getters["user/getAvailableConfigurations"];

      // Redirect to graveyard selection or app depending on number of graveyards user has access to
      if (configurations.length > 1) {
        this.updateView("SelectView");
      } else {
        this.updateView("AppView");
      }

      // Store in session storage for router to go back to graveyard selection
      sessionStorage.setItem("backToSelection", true);
    },
    loadConfiguration: async function (updateView) {
      if (updateView) {
        store.dispatch("management/setConfigurationLoading", true);
        this.updateView("LoadingCrossComponent");
      }

      //Setting up Application -> Load Configuration for Gravemanagement and save to store
      const restClient = new RestClient();

      // await restClient.getGraveyards()
      //   .then(response => store.state.graveyards = response.data);
      let retry = 0;
      let configLoadingFailure = true;
      do {
        await restClient.getConfiguration()
          .then(response => {
            if (response == null) {
              retry++;
            } else {
              store.dispatch("management/setConfiguration", response.data);
              retry = 10;
              configLoadingFailure = false;
            }
          }); // -> !!! Value is used in ConfigurationParser.js file
        await new Promise(r => setTimeout(r, 1000));
      } while (retry < 10)

      if (configLoadingFailure) {
        return console.log("Config couldn't load");
      }

      const userConfiguration = new UserConfiguration();
      let configSettings = {};

      // Load accent color
      configSettings.accentColor = await userConfiguration.getAccentColor();

      // Load font scale
      configSettings.fontScale = userConfiguration.getFontScale();

      // Store settings for this configuration in the session storage to apply later
      sessionStorage.setItem("configSettings", JSON.stringify(configSettings));

      //Setting up Application -> end
      if (updateView) {
        store.dispatch("management/setConfigurationLoading", false);
      }
    },
    updateView: function (view) {
      const currentPath = this.$router.options.history.state.current;
      const redirect = currentPath.includes("/login") ? "/" : currentPath;
      this.$router.push(redirect)
      this.currentView = view;
    },
    saveUserToStore: async function (user) {
      store.dispatch("user/setUser", user);
      store.dispatch("user/setIdToken", await user.getIdToken());
    },
    addCustomIcons: function () {
      const requireSvg = require.context('@/assets/icons', false, /\.svg$/);

      requireSvg.keys().forEach((fileName) => {
        const iconName = fileName.replace('./', '').replace('.svg', ''); // Extract the icon name
        let svgContent = requireSvg(fileName).default; // Load SVG content

        // Remove width and height attributes, and add a viewBox
        svgContent = svgContent.replace(/<svg([^>]*)>/, (match, attributes) => {
          // Extract width and height values
          const widthMatch = attributes.match(/width="([\d.]+)"/);
          const heightMatch = attributes.match(/height="([\d.]+)"/);
          const width = widthMatch ? parseFloat(widthMatch[1]) : null;
          const height = heightMatch ? parseFloat(heightMatch[1]) : null;

          // Build new attributes without width/height and add viewBox
          const cleanedAttributes = attributes
            .replace(/width="[\d.]+"/, '') // Remove width
            .replace(/height="[\d.]+"/, '') // Remove height
            .trim(); // Clean up extra spaces

          const viewBox = width && height ? `viewBox="0 0 ${width} ${height}"` : '';
          return `<svg ${cleanedAttributes} ${viewBox}>`;
        });

        // Function to apply currentColor to fill and stroke attributes for all elements
        const applyCurrentColor = (attributes) => {
          // Handle `fill` attribute
          if (!/fill="none"/.test(attributes)) {
            attributes = attributes.replace(/fill="[^"]*"/, '').trim(); // Remove existing `fill`
            attributes += ' fill="currentColor"';
          }

          // Handle `stroke` attribute
          attributes = attributes.replace(/stroke="[^"]*"/, ''); // Remove existing `stroke`
          attributes += ' stroke="currentColor"';

          return attributes;
        };

        // Apply to all elements (paths, circles, rects, lines, etc.)
        svgContent = svgContent.replace(/<(path|circle|rect|line|polygon|polyline|ellipse|text)([^>]*)\/?>/g, (match, tagName, attributes) => {
          // Apply currentColor to fill and stroke
          const updatedAttributes = applyCurrentColor(attributes);

          return `<${tagName} ${updatedAttributes}/>`;
        });

        ClarityIcons.addIcons([iconName, svgContent]);
      });
    },
  }
}
</script>

<style></style>